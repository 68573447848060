<template>
  <v-app>
    <v-app-bar app color="white">
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/logo.svg"
          transition="scale-transition"
          width="80"
        />
        <b>Track And Races</b>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main class="backimg">
      <v-container>
        <form
          @submit.prevent="register"
          class="mx-auto"
          style="max-width: 500px"
        >
          <v-row style="background: #fff" class="d-flex">
            <v-col class="mx-auto" cols="12">
              <v-card flat>
                <v-card-text>
                  <v-card-title class="d-flex pt-0">
                    <span class="mx-auto">REGISTRATE</span>
                  </v-card-title>
                  <div class="d-flex">
                    <v-text-field
                      :color="colors.perfil"
                      autofocus
                      class="mt-0 mb-3 register"
                      label="Nombre"
                      single-line
                      dense
                      solo
                      flat
                      background-color="blue-grey lighten-5"
                      hide-details
                      prepend-inner-icon="account_circle"
                      v-model="nombre"
                      required
                    ></v-text-field>
                    <v-text-field
                      :color="colors.perfil"
                      class="ml-3 mt-0 mb-3 register"
                      label="Apellido"
                      single-line
                      dense
                      solo
                      flat
                      background-color="blue-grey lighten-5"
                      hide-details
                      v-model="apellido"
                      required
                    ></v-text-field>
                  </div>
                  <v-text-field
                    :color="colors.perfil"
                    class="mb-2 register"
                    label="DNI"
                    single-line
                    dense
                    solo
                    flat
                    background-color="blue-grey lighten-5"
                    hide-details
                    prepend-inner-icon="credit_card"
                    v-model="dniRe"
                    required
                    type="text"
                  ></v-text-field>
                  <p class="ma-0">Fecha de nacimiento</p>
                  <v-text-field
                    :color="colors.perfil"
                    class="mb-3 register"
                    label="F. Nacimiento"
                    single-line
                    dense
                    solo
                    flat
                    background-color="blue-grey lighten-5"
                    hide-details
                    prepend-inner-icon="calendar_today"
                    v-model="nacimientoRe"
                    required
                    type="date"
                  ></v-text-field>

                  <v-text-field
                    :color="colors.perfil"
                    class="mb-3 register"
                    label="E-mail"
                    single-line
                    dense
                    solo
                    flat
                    background-color="blue-grey lighten-5"
                    hide-details
                    prepend-inner-icon="email"
                    v-model="emailRe"
                    required
                    type="email"
                  ></v-text-field>

                  <div class="d-flex">
                    <vue-tel-input
                      @input="onInput"
                      :required="true"
                      :preferred-countries="['ve', 'pe']"
                      class="mb-3 mr-3 phone"
                      placeholder="Teléfono"
                      v-model="phoneRe"
                    ></vue-tel-input>
                    <v-select
                      :color="colors.perfil"
                      class="mb-3 register"
                      label="Moneda"
                      single-line
                      dense
                      solo
                      flat
                      background-color="blue-grey lighten-5"
                      hide-details
                      v-model="moneda"
                      required
                      item-text="moneda"
                      item-value="idmoneda"
                      :items="contryCurrency"
                      :rules="[(v) => !!v || 'Este campo es requerido']"
                    >
                      <template slot="item" slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ data.item.simbolo }} ({{ data.item.moneda }})
                        {{
                          data.item.paisnombre == "ECUADOR"
                            ? "- ECUADOR"
                            : data.item.paisnombre == "EEUU"
                            ? "- EEUU"
                            : ""
                        }}
                      </template>
                    </v-select>
                  </div>
                  <v-text-field
                    :color="colors.perfil"
                    class="mt-0 mb-3 register"
                    label="Pais"
                    single-line
                    dense
                    solo
                    flat
                    background-color="blue-grey lighten-5"
                    hide-details
                    prepend-inner-icon="flag"
                    v-model="rePais"
                    required
                  ></v-text-field>
                  <v-text-field
                    :color="colors.perfil"
                    class="mt-0 mb-3 register"
                    label="Ciudad"
                    single-line
                    dense
                    solo
                    flat
                    background-color="blue-grey lighten-5"
                    hide-details
                    prepend-inner-icon="location_city"
                    v-model="reCuidad"
                    required
                  ></v-text-field>
                  <v-text-field
                    :color="colors.perfil"
                    v-model="passRe"
                    background-color="blue-grey lighten-5"
                    hide-details
                    label="Contraseña"
                    single-line
                    dense
                    solo
                    flat
                    prepend-inner-icon="lock"
                    type="password"
                    class="mb-3 register"
                    required
                  ></v-text-field>
                  <v-text-field
                    :color="colors.perfil"
                    v-model="passConRe"
                    background-color="blue-grey lighten-5"
                    hide-details
                    label="Confirmar contraseña"
                    single-line
                    dense
                    solo
                    flat
                    prepend-inner-icon="lock"
                    type="password"
                    required
                    class="register"
                  ></v-text-field>
                  <div v-if="error">
                    <v-alert dense class="mb-0 mt-3" type="error"
                      >Debe selecionar una moneda.</v-alert
                    >
                  </div>
                  <!-- <span class="red--text d-block mt-4">*Todos los campos son obligatorios</span> -->
                  <v-checkbox
                    :color="colors.perfil"
                    dense
                    hide-details
                    v-model="checkboxMayor"
                    :label="`Confirmo que soy mayor de 18 años`"
                    required
                  ></v-checkbox>
                  <v-checkbox
                    :color="colors.perfil"
                    dense
                    hide-details
                    v-model="checkboxCond"
                    :label="`Acepto los terminos y condiciones`"
                    required
                  ></v-checkbox>
                  <v-btn
                    class="mb-2 mt-8"
                    :color="colors.perfil"
                    :loading="loading"
                    depressed
                    dark
                    block
                    type="submit"
                    >REGISTRARME</v-btn
                  >
                  <v-divider class="my-4"></v-divider>
                  <span class="text-center d-block mt-4">
                    Lee nuestros
                    <a href>terminos y condiciones</a>
                  </span>
                  <span class="text-center d-block mb-0">
                    Contactanos a
                    <b>{{ emailtr }}</b>
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </form>
        <v-dialog v-model="dialogSMS" persistent max-width="400">
          <v-card>
            <v-card-title class="d-flex">
              <span class="mr-auto body-1 font-weight-medium color"></span>
              <v-icon @click="dialogSMS = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
              <span class="body-1 d-block text-center">
                Hola
                <b>{{ this.nombre }},</b>
                hemos enviado un codigo a tu email y dispositivo movil.
              </span>
              <span class="body-1 d-block text-center"></span>
              <span class="body-2 mt-2 d-block text-center"
                >Por favor introduzca el codigo a continuación.</span
              >
              <form @submit.prevent="sendSMS">
                <div class="d-flex align-center">
                  <v-text-field
                    :color="colors.perfil"
                    v-model="codeSMS"
                    background-color="blue-grey lighten-5"
                    hide-details
                    label="Ingrese su codigo"
                    single-line
                    dense
                    solo
                    flat
                    append-icon="mdi-code"
                    type="phone"
                    class="my-3 mr-3"
                  ></v-text-field>
                  <v-btn
                    type="submit"
                    :loading="loading"
                    :disabled="loading"
                    :dark="loading ? false : true"
                    color="blue"
                    depressed
                    >continuar</v-btn
                  >
                </div>
                <b v-if="error" class="red--text font-weight-medium ml-2" dense
                  >Codigo incorrecto</b
                >
              </form>
              <span class="title d-block text-center mt-3"
                >{{ min }} Minutos</span
              >
              <span class="body-2 d-block text-center font-weight-medium mb-3"
                >restantes para volver a solicitar</span
              >
              <v-btn
                @click="resendSMS()"
                text
                small
                block
                :disabled="min != '0:00'"
                >Volver a enviar</v-btn
              >
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    colors: {
      perfil: "primary",
    },
    interval: {},
    contryCurrency: [],
    error: false,
    checkboxMayor: false,
    checkboxCond: false,
    loading: false,
    dialogSMS: false,
    emailtr: "",
    min: "2:00",
    codeSMS: "",
    id: "",
    snackbar: true,

    nombre: "",
    apellido: "",
    dniRe: "",
    nacimientoRe: "",
    emailRe: "",
    phoneRe: "",
    moneda: "",
    rePais: "",
    reCuidad: "",
    passRe: "",
    passConRe: "",

    login: false,
  }),
  mounted() {
    this.$axios.get("https://geolocation-db.com/json/").then((e) => {
      this.rePais = e.data.country_name;
    });
    this.dominio();
  },
  methods: {
    register() {
      if (
        this.passRe == this.passConRe &&
        this.checkboxMayor &&
        this.checkboxCond &&
        this.moneda != ""
      ) {
        let split = this.phoneRe.split("+")[1];
        let area = split.split(" ")[0];
        let phone = this.telRe;

        this.loading = true;
        this.$axios({
          method: "post",
          url: `https://apitr.xyz/onlineapi/api/web_register`,
          data: {
            name: this.nombre + " " + this.apellido,
            email: this.emailRe,
            area: area,
            telefono: phone,
            clave: this.passRe,
            dominio: "trackandraces.com",
            idpais: this.moneda,
            pais: this.rePais,
            ciudad: this.reCuidad,
            dni: this.dniRe,
            f_nacimiento: this.nacimientoRe,
          },
        }).then((response) => {
          console.log(response);
          if (response.data.message == "Mensaje enviado") {
            this.id = response.data.id;
            this.dialogSMS = true;
            this.dialogRegister = false;
            this.countdown();
          } else {
            // this.$store.state.msg = response.data.message;
            // this.snackbar = true;
          }
          this.loading = false;
        });
      } else {
        this.error = true;
      }
    },
    onInput(formattedNumber, { number }) {
      this.phoneRe = number.international;
      this.telRe = number.significant;
    },
    dominio() {
      this.$axios
        .post("/dominio", {
          dominio: "trackandraces.com",
        })
        .then((res) => {
          this.emailtr = res.data.email;
          this.contryCurrency = res.data.paises;
        });
    },
    countdown() {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        var timer = this.min;
        timer = timer.split(":");
        var minutes = timer[0];
        var seconds = timer[1];
        seconds -= 1;
        if (minutes < 0) return;
        else if (seconds < 0 && minutes != 0) {
          minutes -= 1;
          seconds = 59;
        } else if (seconds < 10 && length.seconds != 2) seconds = "0" + seconds;

        this.min = minutes + ":" + seconds;

        if (minutes == 0 && seconds == 0) clearInterval(this.interval);
      }, 1000);
    },
    sendSMS() {
      this.login = false;
      this.loading = true;

      this.$axios({
        method: "post",
        url: `https://apitr.xyz/onlineapi/api/registro_sms`,
        data: {
          id: this.id,
          codigosms: this.codeSMS,
        },
      }).then((response) => {
        if (response.data.message == "El codigo ingresado es Incorrecto") {
          this.error = true;
        } else {
          this.login = true;
          this.error = false;
          // this.$store.state.msg = response.data.message;
          this.snackbar = true;
          this.codeSMS = null;
          // this.dialogSMS = false;
          this.min = "2:00";
          this.nombre = "";
          this.emailRe = "";
          this.phoneRe = "";
          this.passRe = "";
          this.passConRe = "";
          this.checkboxMayor = false;
          this.checkboxCond = false;
          this.email = "";
          this.pass = "";
          this.waiting = false;
          this.doneReset = true;
          window.location.href = "https://trackandraces.com/";
        }
        this.loading = false;
      });
    },
    resendSMS() {
      this.$axios({
        method: "post",
        url: `https://apitr.xyz/onlineapi/api/resend_sms`,
        data: {
          id: this.id,
        },
      }).then((response) => {
        response;
        this.min = "2:00";
        this.countdown();
      });
    },
  },
};
</script>
<style>
.backimg {
  background-image: url("./assets/fondo derecha.svg");
  background-repeat: repeat;
  background-blend-mode: luminosity;
  background-position: center;
  background-color: rgb(3, 3, 56);
}
</style>